import React from 'react';
import { Draw, Select, Translate, Modify } from 'ol/interaction';
import {
  MapDeleteButton,
  MapOperationButton,
  MapZoomButton,
  MapLineworkButton,
  MapHomeButton,
} from '../..';
import {
  MapProps,
  removePolygon,
  lonLatArrayToFeature,
  featureToLonLatArray,
  crearteOverlayControl,
} from '../../../hooks';
import {
  logger,
  LonLat,
  mapViewFitByFeature,
  createPolygonInteraction,
  createSelectionInteraction,
  createModifyInteraction,
  createTranslationInteraction,
} from '../../../utils';
import define from './define';

class TaskMapController {
  currentState: 'init' | 'draw' | 'edit' | 'selection';

  mapProps?: MapProps;

  inited: boolean;

  showLineWork: boolean;

  navigateHomeBoundary: LonLat[];

  polygonSelection: boolean;

  polygonInteraction?: Draw;

  selectionInteraction?: Select;

  modifyInteraction?: Modify;

  translationInteraction?: Translate;

  currentZoom: number;

  /**
   * コンストラクタ
   */
  constructor() {
    this.currentState = 'init';
    this.inited = false;
    this.polygonSelection = false;
    this.showLineWork = false;
    this.navigateHomeBoundary = [];
    this.currentZoom = 0;
  }

  /**
   * 初期化 (再レンダリング時も呼ばれる)
   *
   * @param mapProps Map 関連の独自 Props
   * @param isEditable 編集可能か否か
   * @param onShowInput 入力ダイアログの表示契機
   * @param onChangeLineWork ラインワークの切替契機
   * @param onModify ポリゴン変更通知
   * @param onZoomEnd ズーム変更通知
   */
  init(
    mapProps: MapProps,
    isEditable: boolean,
    onShowInput: (LonLatArray: LonLat[]) => void,
    onChangeLineWork: (newLineWork: boolean) => void,
    onModify: () => void,
    onZoomEnd: (zoom: number) => void,
  ): boolean {
    this.mapProps = mapProps;
    const ret = this.inited; // 初回時のみ false を返したい為、変数に代入している
    const { sourceVector, vectorLayer, map } = mapProps;

    // 再レンダリング時に、再初期化したくない項目を記述する
    if (!this.inited) {
      this.inited = true;
      this.showLineWork = mapProps.defaultLineWork;
      onChangeLineWork(this.showLineWork);

      map.on('moveend', () => {
        onZoomEnd(map.getView().getZoom() || 0);
      });

      // 各インタラクション作成
      this.polygonInteraction = createPolygonInteraction(
        sourceVector,
        0, // featureId
        () => {
          this.setDrawEditState(true); // 描画完了時は getFeatures から期待値が取れないので、引数フラグで制御する
          onModify();
        },
      );
      this.modifyInteraction = createModifyInteraction(
        sourceVector,
        true,
        () => {
          onModify();
        },
      );
      this.selectionInteraction = createSelectionInteraction(vectorLayer);
      this.translationInteraction = createTranslationInteraction(
        this.selectionInteraction,
        () => {
          onModify();
        },
      );
      // 各コントロール作成
      this.addMapZoomControl();
      this.addMapLineWorkControl(onChangeLineWork);
      this.addMapHomeControl();

      if (isEditable) {
        this.addMapDeleteControl();
        this.addMapOperationControl(onShowInput);
      }
    }
    return ret;
  }

  /**
   * デフォルトステートの更新
   */
  updateState() {
    // 操作設定を適応
    switch (this.currentState) {
      case 'init':
      case 'draw':
        this.setDrawEditState();
        break;
      case 'selection':
        this.setSelectionState();
        break;
      default:
    }
  }

  /**
   * ホームボタン押下時にマップの表示位置を調整する緯度経度の配列
   *
   * @param value 設定値
   */
  setNavigateHomeBoundary(value: LonLat[]) {
    this.navigateHomeBoundary = value;
  }

  /**
   * 全てのインタラクションをクリアする
   */
  removeInteractionAll(): void {
    if (!this.mapProps) return;
    const { map } = this.mapProps;
    if (
      !this.polygonInteraction ||
      !this.selectionInteraction ||
      !this.modifyInteraction ||
      !this.translationInteraction
    ) {
      return;
    }
    const interactions = [
      this.polygonInteraction,
      this.selectionInteraction,
      this.modifyInteraction,
      this.translationInteraction,
    ];
    interactions.forEach(it => map.removeInteraction(it));
  }

  /**
   * ポリゴンの 描画/編集 インタラクションを設定する
   *
   * @param forceEdit 強制的に編集状態にするフラグ
   */
  setDrawEditState(forceEdit = false): void {
    if (!this.mapProps) return;
    if (!this.selectionInteraction) return;
    const { map, sourceVector } = this.mapProps;

    // 描画/編集 モード時は、選択状態を解除する
    this.selectionInteraction.getFeatures().clear();

    const setDraw = () => {
      if (this.currentState === 'draw') return;
      if (!this.polygonInteraction) return;
      if (!this.modifyInteraction) return;
      this.removeInteractionAll();
      map.addInteraction(this.polygonInteraction);
      map.removeInteraction(this.modifyInteraction);
      this.currentState = 'draw';
    };

    const setEdit = () => {
      if (this.currentState === 'edit') return;
      if (!this.polygonInteraction) return;
      if (!this.modifyInteraction) return;
      this.removeInteractionAll();
      map.addInteraction(this.modifyInteraction);
      map.removeInteraction(this.polygonInteraction);
      this.currentState = 'edit';
    };
    // ポリゴンの描画有無によって分岐させる
    const isEdit = sourceVector && sourceVector.getFeatures().length > 0;

    if (forceEdit || isEdit) {
      setEdit();
    } else {
      setDraw();
    }
  }

  /**
   * ポリゴンの [選択/移動] インタラクションを設定する
   */
  setSelectionState(): void {
    if (!this.mapProps) return;
    const { map } = this.mapProps;
    if (this.currentState === 'selection') return;
    if (!this.selectionInteraction) return;
    if (!this.modifyInteraction) return;
    if (!this.translationInteraction) return;
    this.removeInteractionAll();
    map.addInteraction(this.selectionInteraction);
    map.addInteraction(this.translationInteraction);
    this.currentState = 'selection';
  }

  /**
   * ポリゴン削除ボタンのコントロールを追加
   */
  addMapDeleteControl(): void {
    if (!this.mapProps) return;
    const { map, sourceVector } = this.mapProps;
    map.addControl(
      crearteOverlayControl(
        'map_del',
        <MapDeleteButton
          onClick={() => {
            if (!this.selectionInteraction) return;
            removePolygon(map, this.selectionInteraction, sourceVector);
            if (this.currentState === 'draw' || this.currentState === 'edit') {
              this.setDrawEditState();
            }
          }}
        />,
        { right: 11, bottom: 12 },
      ),
    );
  }

  /**
   * マップズームボタンのコントロールを追加
   */
  addMapZoomControl(): void {
    if (!this.mapProps) return;
    const { map, view } = this.mapProps;
    const zoom = view.getZoom() || 0;
    const { zoomDuration, changeZoomLevel } = define;
    this.currentZoom = zoom;
    map.addControl(
      crearteOverlayControl(
        'map_zoom',
        <MapZoomButton
          onClickZoomIn={() => {
            const mapZoom = Math.round(view.getZoom() || 0);
            this.currentZoom = mapZoom + changeZoomLevel;
            view.animate({
              duration: zoomDuration,
              zoom: this.currentZoom,
            });
            logger().debug('zoomIn (view after)', this.currentZoom);
          }}
          onClickZoomOut={() => {
            const mapZoom = Math.round(view.getZoom() || 0);
            this.currentZoom = mapZoom - changeZoomLevel;
            if (this.currentZoom < 0) {
              this.currentZoom = 0;
            }
            view.animate({
              duration: zoomDuration,
              zoom: this.currentZoom,
            });
            logger().debug('zoomOut (view after): ', this.currentZoom);
          }}
        />,
        { left: 11, top: 12 },
      ),
    );
  }

  /**
   * ラインワーク切替ボタンのコントロールを追加
   *
   * @param onShowInput 入力ダイアログの表示契機
   */
  addMapLineWorkControl(
    onChangeLineWork: (newLineWork: boolean) => void,
  ): void {
    if (!this.mapProps) return;
    const { map } = this.mapProps;
    map.addControl(
      crearteOverlayControl(
        'map_linework',
        <MapLineworkButton
          defaultShowLineWork={this.showLineWork}
          onClick={() => {
            this.showLineWork = !this.showLineWork;
            onChangeLineWork(this.showLineWork);
          }}
        />,
        { left: 11, top: 111 },
      ),
    );
  }

  /**
   * ホームボタンのコントロールを追加
   */
  addMapHomeControl(): void {
    if (!this.mapProps) return;
    const { map } = this.mapProps;
    map.addControl(
      crearteOverlayControl(
        'map_home',
        <MapHomeButton
          onClick={() => {
            this.navigateToHome();
          }}
        />,
        { left: 11, top: 165 },
      ),
    );
  }

  /**
   * 操作ボタン(入力ダイアログ表示 / 描画モード切替 / 選択モード切替)のコントロールを追加
   *
   * @param onShowInput 入力ダイアログの表示契機
   */
  addMapOperationControl(onShowInput: (LonLatArray: LonLat[]) => void): void {
    if (!this.mapProps) return;
    const { map, sourceVector } = this.mapProps;
    map.addControl(
      crearteOverlayControl(
        'map_opr',
        <MapOperationButton
          onClickInput={() => {
            const features = sourceVector.getFeatures();
            const lonLatArray =
              features.length > 0
                ? featureToLonLatArray(sourceVector.getFeatures()[0])
                : [];
            onShowInput(lonLatArray);
          }}
          onClickDraw={() => {
            this.setDrawEditState();
          }}
          onClickSelect={() => {
            this.setSelectionState();
          }}
        />,
        { left: 11, bottom: 12 },
      ),
    );
  }

  navigateToHome(): void {
    if (!this.mapProps) return;
    const { map } = this.mapProps;
    if (this.navigateHomeBoundary.length <= 0) return;
    const feature = lonLatArrayToFeature(this.navigateHomeBoundary);
    mapViewFitByFeature(map, feature, 0, define.homeDuration);
  }

  /**
   * 経度緯度を元にポリゴンを生成する
   *
   * @param lonlat 経度緯度の配列
   */
  update(lonlat: LonLat[]): void {
    if (!this.mapProps) return;
    const { sourceVector, map } = this.mapProps;
    sourceVector.clear();
    if (lonlat.length <= 0) return;
    const newFeature = lonLatArrayToFeature(lonlat);
    newFeature.setId(0);
    sourceVector.addFeature(newFeature);

    // マップの自動調整
    mapViewFitByFeature(map, newFeature);
  }
}

export default TaskMapController;
